<template>
    <div>
    <div class="b2c-page-bg">
      <a href="/open-credit-card-vpbank">
          <img class="donate-money-image" src="../assets/images/Bannnerrrrrrrr.png" alt=""/>
        </a>
        <div class="b2c-pc-pack-content">
            <div class="row justify-content-between">
                <div class="col-md-7 col-sm-12 ">
                    <b-card class="p-0 m-0 h-100" body-class="p-0">
                        <b-card-text>
                            <div class="row">
                                <div class="col-12">
                                    <h5 class="modal-title">
                                        <img src="@/assets/images/logo/logo.svg" style="width:20%" class="border-right border-2 pr-2" alt="icon-notify">
                                        <img src="@/assets/images/services/VPBank.svg" style="width:30%" class="ml-2" alt="icon-notify">
                                    </h5>
                                    <div class="text-container" style="margin-top:31px">
                                        <p class="text text-intro">
                                          Chúc mừng Bạn đã trúng thưởng chương trình "Mở thẻ VPBank ngay, rinh 200k về tay" khi mở thẻ tín dụng VPBank qua website iTel.VN hoặc App MY iTel.
                                        </p>
                                        <p class="text text-main-italic">
                                          "Mở thẻ VPBank tại iTel ngay rinh 200k về tay" là chương trình khuyến mại diễn ra từ 20/2/2023 - 30/6/2023 được tổ chức bởi Mạng di động iTel và ngân hàng VPBank. Theo đó, Bạn sẽ nhận được số tiền 200.000VNĐ vào tài khoản điện thoại sau khi mở thẻ tín dụng VPBank qua website/app iTel và kích hoạt thẻ thành công.
                                        </p>
                                        <p class="text text-main">
                                          Bạn sẽ nhận được tiền vào tài khoản chính ngay khi điền đầy đủ thông tin và thông tin của Bạn trùng khớp với thông tin đã đăng ký tại VPBank.
                                        </p>
                                        <div class="text text-end">
                                          iTel xin trân trọng cảm ơn!
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </b-card-text>
                    </b-card>
                </div>
                <div class="col-md-4 col-sm-12 user-info-card">
                    <b-card class="p-0 m-0 h-100" body-class="p-0">
                      <b-card-header>Thông tin người nhận thưởng</b-card-header>
                      <b-card-text>
                          <validation-observer ref="infoValid">
                            <div class="row p-2">
                              <div class="col-md-12">
                                <validation-provider #default="{ errors }" rules="required|min:3|space">
                                  <label class="d-block">Họ tên <span class="text-primary">*</span></label>
                                  <b-form-input 
                                    size="sm1" 
                                    maxlength="35"
                                    class="donate-input"
                                    v-model="userInfo.fullName"
                                    :state="errors.length > 0 ? false : null" 
                                    placeholder="Nhập đúng họ tên chủ thẻ đã đăng ký với VPBank"
                                  />
                                  <small class="fs-error-txt" v-if="errors.length > 0">
                                    Vui lòng nhập đúng họ tên chủ thẻ đã đăng ký với VPBank
                                  </small>
                                </validation-provider>
                              </div>

                              <div class="col-md-12">
                                <validation-provider #default="{ errors }" rules="required|min:10">
                                  <label class="d-block">Điện thoại liên lạc <span class="text-primary">*</span></label>
                                  <b-form-input 
                                    type="text"
                                    oninput="this.value = this.value.replace(/[^0-9.]/g, ''); this.value = this.value.replace(/(\..*)\./g, '$1');"
                                    maxlength="10" 
                                    size="sm1" 
                                    class="donate-input"
                                    v-model.trim="userInfo.contactPhone"
                                    :state="errors.length > 0 ? false : null" 
                                    placeholder="Nhập đúng số điện thoại đã đăng ký với VPBank"
                                  />
                                  <small class="fs-error-txt" v-if="errors.length > 0">Vui lòng nhập đúng số điện thoại đăng ký mở thẻ VPBank</small>
                                </validation-provider>
                              </div>

                              <div class="col-md-12">
                                <validation-provider #default="{ errors }" rules="required|email">
                                  <label class="d-block">E-mail <span class="text-primary">*</span></label>
                                  <b-form-input
                                    size="sm1" 
                                    maxlength="40" 
                                    class="donate-input"
                                    v-model.trim="userInfo.email"
                                    :state="errors.length > 0 ? false : null" 
                                    placeholder="Vui lòng nhập Email của bạn"
                                  />
                                  <small class="fs-error-txt" v-if="errors.length > 0">Vui lòng nhập chính xác email</small>
                                </validation-provider>
                              </div>

                              <div class="col-md-12">
                                <validation-provider #default="{ errors }" rules="required">
                                  <label class="d-block">CMND/CCCD <span class="text-primary">*</span></label>
                                  <b-form-input
                                    size="sm1" 
                                    class="donate-input"
                                    v-model.trim="userInfo.cmnd"
                                    :state="errors.length > 0 ? false : null" 
                                    placeholder="Điền số CCCD/CMND"
                                  />
                                  <small class="fs-error-txt" v-if="errors.length > 0">Vui lòng nhập chính xác CMND/CCCD</small>
                                </validation-provider>
                              </div>

                              <div class="col-md-12">
                                <button class="b2c-search-button b2c-search-button-red" @click="onSubmit()">
                                  Nhận thưởng ngay
                                </button>
                              </div>
                            </div>
                          </validation-observer>
                      </b-card-text>
                    </b-card>
                </div>
            </div>
        </div>
    </div>
    <div class="error-info-modal" v-if="errorInfo.isError">
      <div class="error-modal-container">
        <div class="close-btn__error-modal">
          <img alt="close" src="../assets/images/close-icon.png" @click=" errorInfo.isError = false"/>
        </div>
        <div class="main-icon__error-modal">
          <img alt="close" src="../assets/images/X.png"/>
        </div>
        <div class="title__error-modal">
          Lỗi rồi!!!
        </div>
        <div class="content__error-modal">
          {{ errorInfo.infoError }}
        </div>
        <div class="col-md-12 d-flex justify-content-center">
            <button class="b2c-search-button b2c-search-button-red mt-0 mb-4 retype-btn"  @click=" errorInfo.isError = false">
             Nhập lại
            </button>
          </div>
      </div>
    </div>
  </div>
</template>

<script>
import {BCard, BCardText, BCardHeader, BFormInput} from "bootstrap-vue"
import { ValidationProvider, ValidationObserver, extend } from "vee-validate";
import { required, min, email } from "@validations";
import useJwt from "@/auth/jwt/useJwt";

extend("space", (value) => {
  return value.indexOf(" ") > 0;
});

export default {
    components: {
        BCard,
        BCardText,
        BCardHeader,
        ValidationProvider, 
        ValidationObserver,
        BFormInput
    },
    data() {
        return {
          required,
          min, 
          email,
          userInfo: {
            fullName: "",
            contactPhone: "",
            email: "",
            cmnd: ""
          },
          errorInfo: {
            isError: false,
            infoError: ''
          }
        };
    },
    methods: {
      async onSubmit() {
        var validate = await this.$refs.infoValid.validate().then((success) => {
          return success;
        });

        await useJwt
          .post("saveInforCusVpBankBonus", this.userInfo)
          .then((response) => {
            if(response.data.code !== 200) {
              this.errorInfo.isError = true
              this.errorInfo.infoError = response.data.message
            }
            else {
              this.errorInfo.isError = false
              this.$router.push(`/success-donate-money?contactPhone=${this.userInfo.contactPhone}`)
            }
          });
      }
    }
}
</script>

<style scoped>
.b2c-search-button {
  width: calc(100% - 50px);
  filter: drop-shadow(0px 10px 20px rgba(0, 0, 0, 0.1));
  color: #424242;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 11px;
  height: 48px;
  background: #ffffff;
  -webkit-border-radius: 26px;
  border-radius: 26px;
  margin: 20px 31px;
}

.b2c-search-button-red {
  color: #ffffff !important;
  background: #ed1f24 !important;
}
.donate-input::placeholder{
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 18px;
}
@media screen and (min-width: 577px){
  .text-container{
    padding: 0 87px;
  }
  .b2c-pc-pack-content{
    padding-top: 74px;
    padding-bottom: 74px;
  }
  .user-info-card{
    margin-top: 0;
  }
}
@media screen and (max-width: 576px){
  .text-container{
    padding: 0 12px;
  }
  .b2c-pc-pack-content{
    padding-top: 19px;
    padding: 19px 25px;
  }
  .user-info-card{
    margin-top: 26px !important;
  }
}
.text-intro{
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  text-align: justify;
  color: #333333;
}
.text-main-italic{
  margin-top: 31px;
  font-style: italic;
  font-weight: 400;
  font-size: 16px;
  line-height: 135.19%;
  text-align: justify;
  color: #333333;
}
.text-main{
  margin-top: 31px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 135.19%;
  text-align: justify;
  color: #333333;
}
.text-end{
  margin-top: 31px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  color: #333333;
  margin-bottom: 73px;
}
.b2c-page-bg {
  background: rgba(242, 242, 242, 1);
}
.modal-title{
  padding-top: 35px;
  font-size: 30px !important;
  color: '#373737' !important;
  font-weight: 700;
  text-align: center;
}
.vietlott-info{
  line-height: 1.5;
  padding: 10px 30px
}
.donate-money-image{
    width: 100%;
    cursor: pointer;
}
.b2c-pc-pack-content {
  min-height: 550px;
  max-width: 122.4rem;
  margin-left: auto;
  margin-right: auto;
}

.b2c-pc-pack-content .package {
  width: 558px;
  height: 304px;
  background: #ed1f24;
  border-radius: 16px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 50px;
  margin-bottom: 160px;
  position: relative;
}

.b2c-pc-pack-content .package h4 {
  font-size: 16px;
  text-align: center;
  padding-top: 30px;
  color: #fff;
}

.b2c-pc-pack-content .package h2 {
  font-weight: 600;
  letter-spacing: 3px;
  font-size: 36px;
  text-align: center;
  color: #fff;
}

.b2c-pc-pack-content .package .subpackage {
  width: 369px;
  height: auto;
  background: #fff;
  box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.1);
  border-radius: 20px;
  position: absolute;
  left: 94.5px;
  margin-top: 20px;
}

.b2c-pc-pack-content .package .subpackage .sub-header {
  border-bottom: 1px solid #0000001a;
  display: flex;
  height: 64px;
}

.b2c-pc-pack-content .package .subpackage h2 {
  font-weight: 700;
  font-size: 36px;
  line-height: 42px;
  color: #ed1f24;
  font-style: italic;
  width: 50%;
  text-align: left;
  padding: 14px 0px 0px 28px;
}

.b2c-pc-pack-content .package .subpackage h4 {
  width: 50%;
  font-weight: 600;
  font-size: 20px;
  line-height: 23px;
  color: #424242;
  padding-top: 20px;
  padding-right: 30px;
  text-align: right;
}

.b2c-pc-pack-content .package .subpackage h4 > span {
  font-weight: 400;
  font-size: 14px;
}

.b2c-pc-pack-content .package .subpackage .pack-txt {
  padding: 6px 15px 0px 15px;
  max-width: 100%;
}

.b2c-pc-agree,
.b2c-pc-pack-content .pc-step2-button {
  max-width: 558px;
  margin-right: auto;
  margin-left: auto;
  margin-top: 0;
}

.b2c-pc-pack-content .package .b2c-cart-bg {
  max-width: 369px;
  margin-right: auto;
  margin-left: auto;
}

.b2c-pc-pack-content .b2c-cart-add-more {
  max-width: 369px;
  margin-right: auto;
  margin-left: auto;
}

.b2c-pc-pack-content .b2c-pc-payment .package {
  height: 200px;
}

.b2c-pc-pack-content .b2c-pc-info {
  margin-top: 50px;
}

.b2c-pc-pack-content .card-header {
  background: #f8f8f8;
  height: 45px;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  border-top-left-radius: 1rem !important;
  border-top-right-radius: 1rem !important;
}
.error-info-modal {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: #02020296;
  z-index: 99999;
}
.error-modal-container {
  width: 360px;
  min-height: 354px;
  background: #FFFFFF;
  border: 1px solid #FFFFFF;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
}

.close-btn__error-modal {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding: 12px;
}
.close-btn__error-modal > img {
  cursor: pointer;
  width: 19px;
  height: 19px;
}
.main-icon__error-modal {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.main-icon__error-modal > img {
  width: 115px;
  height: 115px;
}
.title__error-modal {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  font-size: 24px;
  margin-top: 24px;
  font-family: 'Roboto';
}
.content__error-modal {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  padding-inline: 20px;
  text-align: center;
  margin: 15px 0;
}

.retype-btn {
  width: fit-content;
  padding-inline: 35px;

}

</style>